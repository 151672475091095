/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import { createBrowserRouter, Navigate, redirect, RouterProvider } from 'react-router-dom'
import { MasterLayout } from '../../theme/layout/MasterLayout'
import { App } from '../App'
// auth
import { ApiService, ApiShowError } from '../../theme/helpers'
import { AuthModel, getAuth, useAuth } from '../modules/auth'
import { AuthLayout } from '../modules/auth/AuthLayout'
import { ForgotPassword } from '../modules/auth/components/ForgotPassword'
import { Login } from '../modules/auth/components/Login'
// settings
import { ErrorsPage } from '../modules/errors/ErrorsPage'
// page
import { initMode, initPath, SuspensedView, validateToken } from '../modules/auth/core/store/componentMenu'
import { FullSreenLayout } from '../modules/auth/FullSreenLayout'
import { IMode } from '../pages/mode/index'
import { OrderManagement } from '../pages/orderManagement'
import { PaymentTerminal } from '../pages/paymentTerminal'
import { TableService } from '../pages/tableService'
import { useTranslation } from 'react-i18next'
import { DashboardWrapper } from '../pages/dashboard/DashboardWrapper'


export async function isAuth() {
  try {
    const auth = getAuth()
    const newAuth = await ApiService.post(`/auth/authenticate/${auth?.user?.id}`)
    return newAuth.data
  } catch (error) {
    ApiShowError(error)
  }
  return undefined
}

const AppRoutes = () => {
  const { t } = useTranslation()
  const { saveAuth } = useAuth()
  const { currentUser } = useAuth() as any

  async function loadInitAuth() {
    const auth: AuthModel | undefined = getAuth()
    if (auth && auth.user?.id) {
      const { isAuthenticated, newAccessToken } = await isAuth()
      if (newAccessToken && isAuthenticated) {
        // setCookie('token', newToken, 365)
        // await saveAuth({ ...auth, token: newAccessToken })
      }
      if (isAuthenticated) { return redirect("/") }
    }
    return null
  }
  const menuStore = null //useStore((state:any) => state.menu)
  let menuRouter = [] as any

  if (menuStore) {
  } else {
    let defaultMenu = [
      {
        path: "",
        element: <Navigate to="/dashboard" />,
        isHiddenMenu: true,
        
      },
      {
        path: "dashboard",
        element: <SuspensedView children={<DashboardWrapper />} />,
        
      },
      {
        title: t('mode'),
        ...initPath("mode"),
        icon: '/media/icons/custom/request.svg',
        loader: validateToken,
        element: <SuspensedView children={<IMode />} />,
        permission: '',
      },

    ] as any
    menuRouter = [...defaultMenu, ...menuRouter]
  }
  let menuFullScreen = [
    {
      title: t('orders-hub'),
      ...initMode("orders-hub"),
      icon: '/media/icons/custom/tnf_order_request.svg',
      element: <SuspensedView children={<OrderManagement />} />,
      permission: ''
    },
    {
      title: t('table-service'),
      ...initMode("table-service"),
      icon: '/media/icons/custom/tnf_advance.svg',
      element: <SuspensedView children={<TableService />} />,
      permission: ''
    },
    {
      title: t('payment-terminal'),
      ...initMode("payment-terminal"),
      icon: '/media/icons/custom/tnf_payment.svg',
      element: <SuspensedView children={<PaymentTerminal />} />,
      permission: ''
    },
  ]
  return <RouterProvider router={createBrowserRouter([
    {
      path: "/",
      element: <App />,
      errorElement: <ErrorsPage />,
      children: [
        {
          path: "/",
          element: <MasterLayout />,
          children: menuRouter,
          loader: validateToken,

        },
        {
          path: "/mode",
          element: <FullSreenLayout />,
          errorElement: <ErrorsPage />,
          // loader: validateToken,
          children: menuFullScreen
        },
        {
          path: "/",
          element: <AuthLayout />,
          errorElement: <ErrorsPage />,
          // loader: loadInitAuth,
          children: [
            {
              path: 'login',
              element: <Login />,
            },
            {
              path: "forgot-password",
              element: <ForgotPassword />,
            },
          ]
        },
      ],
    },
  ])} />
}

export { AppRoutes }

