import axios, { AxiosError, AxiosResponse, InternalAxiosRequestConfig } from 'axios';
import { getAuth } from '../../app/modules/auth';
import { isAuth } from '../../app/router/AppRoutes';
import { clearUserData, getCookie, setCookie } from './utils';
export const ApiService = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
  },
  timeout: 10000
})

// ApiService.defaults.baseURL = process.env.REACT_APP_API_URL;
// ApiService.defaults.timeout = 120 * 1000

ApiService.interceptors.request.use(async (config: any) => {
  // const auth = getAuth() as any
  const token = getCookie('token')

  if (token) {
    config.headers.Authorization = `bearer ${token}`
    config.headers['Access-Control-Max-Age'] = 86400
  }
  // console.log('config',config)
  return config;
}, (error) => Promise.reject(error));

// ApiService.interceptors.response.use(
//   response => response,
//   async (error) => {
//     let status = error.request.status
//     let message = JSON.parse(error.request.response).errorCode
//     console.log('message',message)
//     if (message == '403-284210000') {
//       // window.location.href = '/login'//`/error/403?message=${message}`
//     }
//     return Promise.reject(error);
//   }
// );
type IOriginalRequest = InternalAxiosRequestConfig<any> & { _retry?: boolean } | undefined
let isRefreshing = false;
const onResponse = (response: AxiosResponse): AxiosResponse => {
  // console.log('onResponse',response)
  return response;
}
const onResponseError = async (error: AxiosError): Promise<AxiosError> => {
  let originalRequest: IOriginalRequest = error.config;
  const status = error.response?.status || 0;
  const statuscode = (error.response?.data as any)?.errorCode || 0;

  if (axios.isCancel(error)) {
      console.log('Request cancel', error.message);
      return Promise.reject(error);
  }
  // Kiểm tra mã lỗi
  if (status == 403 && originalRequest && !isRefreshing) {
      if (statuscode !== '403-284210010') {
          clearUserData()
          // console.log('aaaaaaaaaa')
          // window.location.href = '/login'
          return Promise.reject(error);
      }
      isRefreshing = true;
      const token = getCookie('token')
      if (!token) {
          return Promise.reject(error);
      }
      try {
          // Gọi API để lấy token mới
          // const refresh = await isAuth()
          const { isAuthenticated, newAccessToken } = await isAuth()
          
        // if (newAccessToken && isAuthenticated) await saveAuth({ ...auth, token: newAccessToken })
        // if (isAuthenticated) { return redirect("/") }
          const newToken = newAccessToken
          if (!newToken) {
              return Promise.reject(error);
          }

          setCookie('token', newToken, 365)
          ApiService.defaults.headers.common['Authorization'] = `Bearer ${newToken}`;
          // Call lại request đã bị lỗi
          return ApiService(originalRequest);
      } catch (error) {
          clearUserData()
          return Promise.reject(error);
      } finally {
          isRefreshing = false;
      }
  }
  // Nếu không, trả lỗi về điểm cuối đã gọi api
  return Promise.reject(error);
}
ApiService.interceptors.response.use(onResponse, onResponseError)

