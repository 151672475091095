import { useStore } from "../modules/auth/core/store"

export function getLocationData() {
  return { title: '', permission: '' }
}

export interface SideBarMenuItem {
  title: string
  path: string
  permission: string
  icon?: string
  children?: SideBarMenuItem[],
  defaultOpen?: boolean
  isLink?: boolean
}
// export const sideBarMenuData = (): SideBarMenuItem[] => {
  export function SideBarMenuData() {
    let dataMenu = useStore((state:any) => state.menu)||[]
      // console.log('dataMenu',dataMenu)
      let data = JSON.parse(JSON.stringify(dataMenu))
      // data = data?.filter((item:any) => {
      //   if(item.children && item.children.length != 0) {
      //     item.children = item.children.filter((i:any)=>!i.isHiddenMenu)
      //   }
      //   if(!item.isHiddenMenu) {
      //     return item
      //   } 
      // })||[]
      // data = sortArrayByDependency(data,'up_Pgm_No','pgm_No')
      // console.log('dataMenu',dataMenu)
      let data1 = [{
        // title: 'Dashboard',
        // path: 'dashboard',
        // icon: 'bi bi-house',
        // permission: ''
        // children: [
        //   {
        //     title: 'Organization Structure',
        //     path: 'organization-structure',
        //     icon: '/media/icons/custom/tnf_order_request.svg',
        //     permission: '500002'
        //   },
        // ]
      },]
    
      return sideBarMenuData
    }
export const sideBarMenuData: SideBarMenuItem[] = [
  {
  title: 'Dashboard',
  path: 'dashboard',
  icon: 'bi bi-house',
  permission: ''
},
{
  title: 'Mode',
  path: 'mode',
  icon: '/media/icons/custom/request.svg',
  permission: '',
  defaultOpen: true,
  isLink: true,
  children: [
    {
      title: 'Orders Hub',
      path: 'orders-hub',
      icon: '/media/icons/custom/tnf_order_request.svg',
      permission: '',
    },
    {
      title: 'Table Service',
      path: 'table-service',
      icon: '/media/icons/custom/tnf_advance.svg',
      permission: ''
    },
    {
      title: 'Payment Terminal',
      path: 'payment-terminal',
      icon: '/media/icons/custom/tnf_payment.svg',
      permission: ''
    },
   
  ]
},
]

