import { useQuery } from "react-query";
import { ApiService } from "../../../theme/helpers/ApiService";

//hosptransactions
export const useTransactions = (query?:string) => useQuery(['transactions'], () => {
    if(!query) {
        query = ''
    }
    return ApiService.get(`/smarthub/hosptransactions/${query}`).then(res => res.data)
})
export const useTransactionsCounttoday = (query?:string) => useQuery(['useTransactionsCounttoday'], () => {
    if(!query) {
        query = ''
    }
    return ApiService.get(`/smarthub/hosptransactions/${query}`).then(res => res.data)
})
//menustructures
export const useMenuStructures = (query?:string) => useQuery(['menustructures'], () => {
    // console.log('ádasdasdsa',query)
    if(!query) {
        query = ''
    }
    return ApiService.get(`smarthub/menustructures/find/findformerchantdisplay/${query}`).then(res => res.data)
})
//hospdiningtables
export const useHospdiningtablesWithQuery = (query?:string) => useQuery(['hospdiningtables'+query], () => {
    if(!query) {
        query = ''
    }
    return ApiService.get(`smarthub/hospdiningtables/${query}`).then(res => res.data)
}, { enabled: !!query })
//hospdiningtables
export const useHospdiningareasections = (query?:string) => useQuery(['hospdiningareasections'], () => {
    if(!query) {
        query = ''
    }
    return ApiService.get(`smarthub/hospdiningareasections/merchant/${query}`).then(res => res.data)
})
//hospdiningtables
export const useHospdiningareasections2 = (query?:string) => useQuery(['hospdiningareasections2'], () => {
    if(!query) {
        query = ''
    }
    return ApiService.get(`smarthub/hospdiningareasections/merchant2/${query}`).then(res => res.data)
}, )//{refetchIntervalInBackground: true, refetchInterval: 5000}
//payment-terminal
export const usePaymentTerminal = (merchantid:string,query?: any) => useQuery(['PaymentTerminal'+merchantid+query], () => {
    if(!query) {
        query = ''
    }
    return ApiService.get(`/smarthub/hosptransactions/${merchantid}/allday/${query}`).then(res => res.data)
}, { enabled: !!merchantid })
export const useCountPaymentTerminal = (merchantid:string,query?: any) => useQuery(['useCountPaymentTerminal'+merchantid+query], () => {
    if(!query) {
        query = ''
    }
    return ApiService.get(`/smarthub/hosptransactions/${merchantid}/countallday/${query}`).then(res => res.data)
}, { enabled: !!merchantid })