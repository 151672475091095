import Swal from "sweetalert2"
import { getIsPermission } from "../../app/modules/auth/Permission"
import { popupLoading } from "../../app/modules/messages"
import { ApiService } from "./ApiService"
import { getLocalStorage } from "./utils"

export const toAbsoluteUrl = (pathname: string) => process.env.PUBLIC_URL + pathname
export const parentLink = (pathname: string) => {
  let arr = pathname.split('/')
  arr.pop()
  return arr?.join('/')
}
export const sortNameAlphabet = (arr: any, keyword: string) => {
  return arr?.sort(function (a: any, b: any) {
    let textA = a[keyword]?.toUpperCase().trim();
    textA = textA?.split(' ').pop()
    let textB = b[keyword]?.toUpperCase().trim();
    textB = textB?.split(' ').pop()
    return textA?.localeCompare(textB)//(textA < textB) ? -1 : (textA > textB) ? 1 : 0;
  });
}

export const sortByAlphabet = (arr: any, keyword: string) => {
  return arr?.sort(function (a: any, b: any) {
    let textA = a[keyword]?.toUpperCase().trim();
    let textB = b[keyword]?.toUpperCase().trim();
    return textA?.localeCompare(textB)//(textA < textB) ? -1 : (textA > textB) ? 1 : 0;
  });
}

export const sortByNumber = (arr: any, keyword: string, order?: string) => {
  if (order == 'DESC') {
    return arr?.sort((a: any, b: any) => (Number(a[keyword]) < Number(b[keyword])) ? 1 : ((Number(b[keyword]) < Number(a[keyword])) ? -1 : 0));
  }
  return arr?.sort((a: any, b: any) => (Number(a[keyword]) > Number(b[keyword])) ? 1 : ((Number(b[keyword]) > Number(a[keyword])) ? -1 : 0));
}

export const sortByUpadateAt = (arr: any) => {
  if (arr) {
    return arr.sort((b: any, a: any) => new Date(a.modifiedon).getTime() - new Date(b.modifiedon).getTime());
  }
  return arr
}

export function numberToTime(number: number) {
  if (number < 10) {
    return `0${number}`
  }
  return `${number}`
}
export const removeAccents = (str: string, text?: boolean) => {
  var AccentsMap = ['aàảãáạăằẳẵắặâầẩẫấậ', 'AÀẢÃÁẠĂẰẲẴẮẶÂẦẨẪẤẬ', 'dđ', 'DĐ', 'eèẻẽéẹêềểễếệ', 'EÈẺẼÉẸÊỀỂỄẾỆ', 'iìỉĩíị', 'IÌỈĨÍỊ', 'oòỏõóọôồổỗốộơờởỡớợ', 'OÒỎÕÓỌÔỒỔỖỐỘƠỜỞỠỚỢ', 'uùủũúụưừửữứự', 'UÙỦŨÚỤƯỪỬỮỨỰ', 'yỳỷỹýỵ', 'YỲỶỸÝỴ',]
  for (var i = 0; i < AccentsMap.length; i++) {
    var re = new RegExp('[' + AccentsMap[i].substr(1) + ']', 'g')
    var char = AccentsMap[i][0]
    str = str.replace(re, char).toLowerCase()
  }
  if (text) {
    return str
  }
  return str.replace(/ /g, '-').replace(/[^\w-]+/g, '')
}

export const sortWithDate = (values: any[], updates: { sort: string, order?: 'asc' | 'desc' }) => {
  if (!Array.isArray(values) || (Array.isArray(values) && values.length == 0)) return []
  let tempValues: Array<any> = values
  let sortColumn: string = String(updates.sort)

  const temp = tempValues[0][sortColumn] ? new Date(tempValues[0][sortColumn]) : undefined
  if (!(temp instanceof Date)) return tempValues;

  let sortType = updates.order || 'asc'
  if (sortType === 'asc') {
    tempValues = tempValues.sort((a: any, b: any) => {
      return new Date(a[sortColumn]).getTime() - new Date(b[sortColumn]).getTime()
    })
  } else if (sortType === 'desc') {
    tempValues = tempValues.sort((a: any, b: any) => {
      return new Date(b[sortColumn]).getTime() - new Date(a[sortColumn]).getTime()
    })
  }
  return tempValues
}

export const sortWithAny = (values: any[], updates: { sort: string, order?: 'asc' | 'desc' }) => {
  if (!Array.isArray(values) || (Array.isArray(values) && values.length == 0)) return []
  let tempValues: Array<any> = values
  let sortColumn: string = String(updates.sort)

  let sortType = updates.order || 'asc'
  if (sortType === 'asc') {
    tempValues = tempValues.sort((a: any, b: any) => {
      if (typeof a[sortColumn] === 'string') {
        return ('' + a[sortColumn]).localeCompare(b[sortColumn], undefined, {
          numeric: true,
          sensitivity: 'base',
        })
      }
      return Number(a[sortColumn]) - Number(b[sortColumn])
    })
  } else if (sortType === 'desc') {
    tempValues = tempValues.sort((a: any, b: any) => {
      if (typeof a[sortColumn] === 'string') {
        return ('' + b[sortColumn]).localeCompare(a[sortColumn], undefined, {
          numeric: true,
          sensitivity: 'base',
        })
      }
      return Number(b[sortColumn]) - Number(a[sortColumn])
    })
  }
  return tempValues
}

export const sortByUpdateAt = (values: any) => {
  if (values && values.length) {
    return values.sort((b: any, a: any) => new Date(a.modifiedat).getTime() - new Date(b.modifiedat).getTime());
  }
  return values
}

export const reFormatCurrent = (value: any) => {
  value = value?.toString()
  value = value?.replaceAll('.', '')
  if (value?.search(',') > -1) {
    value = value?.replaceAll(',', '.')
  }
  return Number(value)
}

export const unique_arr = (arr: any, keyword: string) => {
  var newArr = [] as any
  newArr = arr?.filter(function (item: any) {
    return newArr.findIndex((i: any) => i[keyword] == item[keyword]) > -1 ? '' : newArr.push(item)
  })
  return newArr
}

export const formatCurrent = (value: any) => {
  let minimumFractionDigits = value?.toString().split('.')
  if (minimumFractionDigits?.length == 2) {
    minimumFractionDigits = minimumFractionDigits[1].length
  }
  else {
    minimumFractionDigits = 0
  }
  if (isNaN(value)) {
    value = 0
  }
  return Number(value).toLocaleString('vi-VN', { minimumFractionDigits: minimumFractionDigits })
}

export function formatOptions(data: any[], keys: string[]) {
  if (keys.length == 2 && typeof keys[0] == 'string' && typeof keys[1] == 'string') {
    return data
      .filter(f => (f[keys[0]] && f[keys[1]]))
      .map(item => {
        if (item.hasOwnProperty("value")) {
          item.value2 = item.value
        }
        return ({ ...item, value: item[keys[0]], label: item[keys[1]] })
      })
  }
  if (keys.length == 3 && typeof keys[0] == 'string' && typeof keys[1] == 'string') {
    return data
      .filter(f => (f[keys[0]] && f[keys[1]] && f[keys[2]]))
      .map(item => ({ value: item[keys[0]], label: `${item[keys[1]]}_${item[keys[2]]}`, ...item }))
  }
  return []
}

export function calculatePercentage(value: number, total: number) {
  if (value == 0 && total == 0) return '0'
  return (value * 100 / total).toFixed(0)
}

export function handleSearch(arrayData: any[], keys: string[], word: string) {
  if (keys.length && word.length) {
    let items: any[] = []
    arrayData.forEach((item) => {
      if (item) {
        const isTemp = keys.filter(field => {
          if (item[field]) {
            if (!items.map(e => e.id).includes(item[field].id)) {
              return removeAccents(item[field].toLowerCase(), true).indexOf(removeAccents(word.toLowerCase(), true)) >= 0;
            }
          }
          return false
        }).length
        isTemp && items.push(item)
      }
    });
    arrayData = items
  }
  return arrayData
}

export function getValueId(id?: string, permisstion?: string) {
  const isEdit = typeof id == 'string' && id != 'new'
  return {
    isEditWithPermisstion: permisstion != undefined && getIsPermission([permisstion, 'UPDATE']) && isEdit,
    valueid: isEdit ? id : undefined,
    isEdit
  }
}

export function getParams(keys: string[]) {
  const params: any = new Proxy(new URLSearchParams(window.location.search), {
    get: (searchParams, prop: string) => searchParams.get(prop),
  });
  let datas: any = {}
  keys.forEach(item => {
    datas[item] = params[item] || undefined
  })
  return datas
}

export function onlyUnique(value: any, index: number, array: any[]) {
  return array.indexOf(value) === index;
}

export function formatLable(string: string) {
  string = string.replace(/_/g, ' ')
  return string.charAt(0).toUpperCase() + string.slice(1);
}

/* eslint-disable */
export function getTextCurrentEn(n: number) {
  var string = n.toString(), units, tens, scales, start, end, chunks, chunksLen, chunk, ints, i, word, words, and = 'and';

  /* Remove spaces and commas */
  string = string.replace(/[, ]/g, "");

  /* Is number zero? */
  if (parseInt(string) === 0) return 'zero';

  /* Array of units as words */
  units = ['', 'one', 'two', 'three', 'four', 'five', 'six', 'seven', 'eight', 'nine', 'ten', 'eleven', 'twelve', 'thirteen', 'fourteen', 'fifteen', 'sixteen', 'seventeen', 'eighteen', 'nineteen'];

  /* Array of tens as words */
  tens = ['', '', 'twenty', 'thirty', 'forty', 'fifty', 'sixty', 'seventy', 'eighty', 'ninety'];

  /* Array of scales as words */
  scales = ['', 'thousand', 'million', 'billion', 'trillion', 'quadrillion', 'quintillion', 'sextillion', 'septillion', 'octillion', 'nonillion', 'decillion', 'undecillion', 'duodecillion', 'tredecillion', 'quatttuor-decillion', 'quindecillion', 'sexdecillion', 'septen-decillion', 'octodecillion', 'novemdecillion', 'vigintillion', 'centillion'];

  /* Split user argument into 3 digit chunks from right to left */
  start = string.length;
  chunks = [];
  while (start > 0) {
    end = start;
    chunks.push(string.slice((start = Math.max(0, start - 3)), end));
  }

  /* Check if function has enough scale words to be able to stringify the user argument */
  chunksLen = chunks.length;
  if (chunksLen > scales.length) return '';

  /* Stringify each integer in each chunk */
  words = [];
  for (i = 0; i < chunksLen; i++) {
    chunk = parseInt(chunks[i]);
    if (chunk) {
      /* Split chunk into array of individual integers */
      ints = chunks[i].split('').reverse().map(parseFloat);
      /* If tens integer is 1, i.e. 10, then add 10 to units integer */
      if (ints[1] === 1) {
        ints[0] += 10;
      }
      /* Add scale word if chunk is not zero and array item exists */
      if ((word = scales[i])) {
        words.push(word);
      }
      /* Add unit word if array item exists */
      if ((word = units[ints[0]])) {
        words.push(word);
      }
      /* Add tens word if array item exists */
      if ((word = tens[ints[1]])) {
        words.push(word);
      }
      /* Add 'and' string after units or tens integer if: */
      if (ints[0] || ints[1]) {
        /* Chunk has a hundreds integer or chunk is the first of multiple chunks */
        if (ints[2] || !i && chunksLen) {
          words.push(and);
        }
      }
      /* Add hundreds word if array item exists */
      if ((word = units[ints[2]])) {
        words.push(word + ' hundred');
      }
    }
  }
  return words.reverse().join(' ');
};

var t = ["không", "một", "hai", "ba", "bốn", "năm", "sáu", "bảy", "tám", "chín"]
function r(r: any, n: any) {
  var o = "",
    a = Math.floor(r / 10),
    e = r % 10;
  return a > 1 ? (o = " " + t[a] + " mươi", 1 == e && (o += " mốt")) : 1 == a ? (o = " mười", 1 == e && (o += " một")) : n && e > 0 && (o = " lẻ"), 5 == e && a >= 1 ? o += " lăm" : 4 == e && a >= 1 ? o += " bốn" : (e > 1 || 1 == e && 0 == a) && (o += " " + t[e]), o
}
function n(n: any, o: any) {
  var a = "",
    e = Math.floor(n / 100),
    n = n % 100 as any;
  return o || e > 0 ? (a = " " + t[e] + " trăm", a += r(n, !0)) : a = r(n, !1), a
}
function o(t: any, r: any) {
  var o = "",
    a = Math.floor(t / 1e6),
    t = t % 1e6 as any;
  if (a > 0) {
    o = `${n(a, r)} triệu`
    r = !0
  }
  var e = Math.floor(t / 1e3), t = t % 1e3 as any;
  return e > 0 && (o += n(e, r) + " ngàn", r = !0), t > 0 && (o += n(t, r)), o
}

export function getTextCurrent(r: any) {
  if (0 == r) return t[0];
  var n = "", a = "";
  do var ty = r % 1e9 as any,
    r = Math.floor(r / 1e9) as any,
    n = r > 0 ? o(ty, !0) + a + n : o(ty, !1) + a + n,
    a = " tỷ"; while (r > 0);
  return n.trim()
}
/* eslint-disable */
export const exportCSV = async(recordsData:any,fileName: string) => {
  popupLoading()
  await ApiService.post(`utilities/exportcsv`,
      { filename: fileName,records: recordsData},
      { responseType: 'blob' }
  ).then((response) => {
      const url = URL.createObjectURL(new Blob([response.data], {
          // type: 'application/vnd.ms-excel'
          type: 'text/csv'
          
      }))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', fileName)
      document.body.appendChild(link)
      link.click()
  }).catch(err => {
      console.log('err', err)
  })
  Swal.close()
}
export function jsUcfirst(string:string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}
export function sortArrayByDependency(arr: any, parentid: string, valueid: string,  sortOrder?: string) {
  const result = [] as any;
  // Tìm nút gốc
  const roots = arr?.filter((obj: any) => !obj[parentid]);
  // Đệ quy để tìm các nút con
  const findChildren = (parent: any) => {
    let children = arr.filter((obj: any) => obj[parentid] === parent[valueid]);
    if (sortOrder) {
      children = sortByNumber(children, sortOrder)
    }
    if (children.length === 0) {
      return;
    }
    parent.children = children;
    children.forEach((child: any) => {
      findChildren(child);
    });

  };
  // Đệ quy từ các nút gốc
  roots.forEach((root: any) => {
    findChildren(root);
    result.push(root);
  });
  return result;

}
export function timeSince(today: any, date:any) {
  const {selectedLang} = getLocalStorage('i18nConfig')|| {selectedLang: 'en'} as any

  // let today = new Date().getTime()
  console.log('today - date',date - today)
  var seconds = Math.floor((date - today) / (1000));
  var interval = seconds / 31536000;
  if (interval > 1) {
  let days =  Math.floor((seconds-(Math.floor(interval)*31536000))/86400);
  let content = Math.floor(interval) + " năm " + days + ' ngày';
    if(selectedLang == 'en') {
      content = Math.floor(interval) + " years " + days + ' days'
    }
    return content
  }
  // interval = seconds / 2592000;
  // if (interval > 1) {
  //   let content = Math.floor(interval) + " tháng";
  //   if(selectedLang == 'en') {
  //     content = Math.floor(interval) + " mounths"
  //   }
  //   return content
  // }
  interval = seconds / 86400;
  if (interval > 1) {
    let content = Math.floor(interval) + " ngày";
    if(selectedLang == 'en') {
      content = Math.floor(interval) + " days"
    }
    return content
  }
  interval = seconds / 3600;
  if (interval > 1) {
    let content = Math.floor(interval) + " giờ";
    if(selectedLang == 'en') {
      content = Math.floor(interval) + " hours"
    }
    return content
  }
  interval = seconds / 60;
  if (interval > 1) {
    let content = Math.floor(interval) + " phút";
    if(selectedLang == 'en') {
      content = Math.floor(interval) + " minutes"
    }
    return content
  }
  interval = interval < 0? 0 : interval
  let content = Math.floor(interval) + " giây";
    if(selectedLang == 'en') {
      content = Math.floor(interval) + " seconds"
    }
  return content;
}