/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useState } from 'react'
import { Outlet } from 'react-router-dom'
import { useStoreMode } from './core/store'
import { AuthLayoutMode } from '../../pages/mode/authMode'

const FullSreenLayout = () => {
  const storeMode = useStoreMode((state:any) => state)
  const [show,setShow] = useState(false)
  
  useEffect(() => {
    console.log('into mode',storeMode.info)
    if(!storeMode.info) {
      setShow(true)
    }
    const root = document.getElementById('root')
    if (root) root.style.height = '100%'
    return () => {
      if (root) root.style.height = 'auto'
    }
  }, [])
  return (
    <div className='d-flex flex-column flex-lg-row h-100  '>
      {/* begin::Body */}
      <div className='d-flex flex-column w-100 px-2'>
        {/* begin::Form */}
          {/* begin::Wrapper */}
          {storeMode.info && <Outlet />}
            
          {/* end::Wrapper */}
        {/* end::Form */}
      </div>
      {/* end::Body */}
      <AuthLayoutMode show={show} setShow={setShow}/>
    </div>
  )
}

export { FullSreenLayout }

