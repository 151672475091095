import Swal from "sweetalert2";
import { toast } from "react-toastify";

const isShowSwal = Swal.isVisible()

export function popupMessage(option: { icon: "success" | "error" | "info", description?: any, autoClose?: boolean, title?: any }) {
    if (isShowSwal) return undefined
    Swal.fire({
        icon: option.icon,
        title: option.title ? option.title : option.icon == "success" ? "Your work has been saved" : "An error occurred",
        text: option.description || "",
        showConfirmButton: option.autoClose ? false : true,
        timer: option.autoClose != undefined ? 1500 : undefined,
        confirmButtonText: "Đóng",
        cancelButtonText: "Hủy bỏ",
        customClass: {
            confirmButton: "btn btn-sm btn-primary bg-primary order-2",
            cancelButton: "btn btn-sm btn-secondary bg-secondary order-1",
            closeButton: "btn btn-sm btn-secondary bg-secondary"
        },
    })
}

export async function popupDelete() {
    const result = await Swal.fire({
        text: `Bạn có muốn xóa hàng này không?`,
        icon: "warning",
        buttonsStyling: false,
        showCancelButton: true,
        confirmButtonText: "Thực hiện",
        cancelButtonText: "Hủy bỏ",
        customClass: {
            confirmButton: "btn btn-sm btn-danger order-2",
            cancelButton: "btn btn-sm btn-secondary bg-secondary order-1",
        },
    });
    if (result.isConfirmed) {
        return true;
    }
    return false;
}

export async function popupQuestion(option: { title?: string, description?: string, confirmButtonText?: string, cancelButtonText?: string, denyButtonText?: string, denyButton?: string, confirmButton?: string, cancelButton?:string, showDenyButton?:boolean }) {
    const result = await Swal.fire({
        title: option.title || "",
        text: option.description || "",
        icon: "question",
        buttonsStyling: false,
        showCancelButton: true,
        showDenyButton: option.showDenyButton,
        confirmButtonText: option.confirmButtonText ? option.confirmButtonText : "Thực hiện",
        cancelButtonText: option.cancelButtonText ? option.cancelButtonText : "Hủy bỏ",
        denyButtonText: option.denyButtonText ? option.denyButtonText : "Hủy bỏ",
        customClass: {
            confirmButton: `btn btn-sm ${option.confirmButton ? option.confirmButton : "btn-primary"} order-2`,
            cancelButton: `btn btn-sm ${option.cancelButton ? option.cancelButton : "btn-secondary bg-secondary"} order-1`,
            denyButton: `btn btn-sm ${option.denyButton ? option.denyButton : "btn-danger bg-danger"} order-3`,
        },
    });
    if (result.isConfirmed) {
        return true;
    }
    
    return false
}
export async function popupCustomQuestion(option: { title?: string, description?: string, confirmButtonText?: string, cancelButtonText?: string, denyButtonText?: string, denyButton?: string, confirmButton?: string, cancelButton?:string, showDenyButton?:boolean }) {
    const result = await Swal.fire({
        title: option.title || "",
        text: option.description || "",
        icon: "question",
        buttonsStyling: false,
        showCancelButton: true,
        showDenyButton: option.showDenyButton,
        confirmButtonText: option.confirmButtonText ? option.confirmButtonText : "Thực hiện",
        cancelButtonText: option.cancelButtonText ? option.cancelButtonText : "Hủy bỏ",
        denyButtonText: option.denyButtonText ? option.denyButtonText : "Hủy bỏ",
        customClass: {
            confirmButton: `btn btn-sm ${option.confirmButton ? option.confirmButton : "btn-primary"} order-2`,
            cancelButton: `btn btn-sm ${option.cancelButton ? option.cancelButton : "btn-secondary bg-secondary"} order-1`,
            denyButton: `btn btn-sm ${option.denyButton ? option.denyButton : "btn-danger bg-danger"} order-3`,
        },
    });
    return result
}

export const popupLoading = () => {
    if (isShowSwal) return undefined
    Swal.fire({
        html: `<div class='custom-loading'><span>Vui lòng đợi trong giây lát!!!</span> <span class='loader'></span></div>`,
        buttonsStyling: false,
        timerProgressBar: true,
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        showConfirmButton: false,
    })
}

export function popupClose() {
    Swal.close()
}


export function popupMessageHTMl(option: { icon: "info"|"success", title: string, html?: string | HTMLElement | JQuery | undefined, footer?: string | HTMLElement | JQuery | undefined }) {
    if (isShowSwal) return undefined
    Swal.fire({
        ...option,
        // showCloseButton: true,
    })
}
export async function popupInputHTML(option: { icon?: "info", title?: string, html?: string | HTMLElement | JQuery | undefined, footer?: string | HTMLElement | JQuery | undefined }) {
    let messageText = ''
    const result = await Swal.fire({
        ...option,
        buttonsStyling: false,
        showCancelButton: true,
        confirmButtonText: "Thực hiện",
        cancelButtonText: "Hủy bỏ",
        customClass: {
            confirmButton: "btn btn-sm btn-primary order-2",
            cancelButton: "btn btn-sm btn-secondary bg-secondary order-1",
        },
        preConfirm: (message: any) => {
            messageText = message
        },
    });
    if (result.isConfirmed) {
        return messageText;
    }
    return false;
}
export function popupAlert(title: string,type: 'error' | 'warning' | 'success' | 'info' ) {
    let tempToast = toast as any
    tempToast[type](title, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
    });
    
}