import { yupResolver } from "@hookform/resolvers/yup";
import clsx from "clsx";
import { useEffect, useRef, useState } from "react";
import { Dropdown } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import * as yup from "yup";
import { ApiService, getValueId, parentLink } from "../../../theme/helpers";
import { ContentHeader } from "../../../theme/layout/components/content";
import { InputDate } from "../../../theme/partials";
import { useCountPaymentTerminal } from "../../modules/api/transactions";
import { useAuth } from "../../modules/auth";
import { useStoreMode } from "../../modules/auth/core/store";
import { ModalAddEditOrder } from "../orderManagement/tabs/addOrder";
import { MBar } from "./tabs/live";
import { useTranslation } from "react-i18next";


const schema = yup.object().shape({
    // itemno: yup.string().required(),
    // unitprice: yup.number().required(),
    // unitcost: yup.number().required(),
    // lastdirectcost: yup.number().required()
});

const PaymentTerminal = () => {
    // const { permission } = getLocationData()
    const navigate = useNavigate();
    const { id } = useParams()
    const { isEdit, valueid } = getValueId(id)
    const [status,setStatus] = useState('Open')
    const [tableData,setTableData] = useState<any>([])
    const {pathname} = useLocation()
    const { currentUser } = useAuth() as any
    //smarthub/:merchantid/uncompleted
    let staffInfo = useStoreMode((e: any)=> e.info)?.staffInfo || {}
    // const { data: dataQuery, isSuccess, refetch, isFetching } = usePaymentTerminal(`${staffInfo.merchantid}`,`?staffcode=${staffInfo.code}&transactionstatus=${status}`)
    const { data: dataCount, isSuccess, refetch, isFetching } = useCountPaymentTerminal(`${staffInfo.merchantid}`,`?staffcode=${staffInfo.code}`)
    const [countDataTabs,setCountDataTabs] = useState()
    useEffect(()=>{
      if(!isFetching && dataCount) {
        setCountDataTabs(dataCount)
      }  
    },[isFetching])

    const callBackFunc = (status: any) => {
      // console.log('status',status)
      setStatus(status)
      setValue('tab',status)
      dataForTab(status)
    }
    // useEffect(() => {
    //   if(!isFetching && dataQuery) {
    //     let tempData = sortWithDate(dataQuery,{order:'desc',sort:'transactiontime'})
    //     console.log('tempData',tempData)
    //     setTableData(tempData)
    //   }  
    // }, [isFetching])
    const [filter, setFilter] = useState<any>(null)
    // const [isSearch, setisSearch] = useState<any>(false)
    const [sortTime,setSortTime] = useState(0)
    const [searchOn,setSearchOn] = useState(false)
    const [edit, setEdit] = useState(false)
    const [dataModal ,setDataModal] = useState<any>({})
    const [dataTab ,setDataTab] = useState<any>([])
    useEffect(() => {
      dataForTab('Open')
    }, [])
    const dataForTab = async(status: string) => {
      let param = ''
      if(status == 'Completed' || status == 'Cancel') {
        param = '&expand=DiningTableHistoryEntries'
      }
      const urlApi = `/smarthub/hosptransactions/${staffInfo.merchantid}/allday?staffcode=${staffInfo.code}&transactionstatus=${status}${param}`
      let {data} = await ApiService.get(urlApi)
      // console.log('data',data)
      setDataTab(data)
      // setStatusActive(status)
      
    }
    const ws = useRef<any>(null);
    const intervalId = useRef<any>(null);
    const connectWebSocket = () => {
      const url = process.env.REACT_APP_URL_API as string
      ws.current = new WebSocket(url);

      ws.current.onopen = () => {
          console.log('WebSocket connected');
          // Send a keep-alive message every 30 seconds
          intervalId.current = setInterval(() => {
              if (ws.current.readyState === WebSocket.OPEN) {
                  ws.current.send(JSON.stringify({ type: 'keep-alive' }));
              }
          }, 30000);
      };

      ws.current.onmessage = (event: any) => {
        let wsdata = event.data;

        if (wsdata) {
          wsdata = JSON.parse(wsdata);
          console.log('WebSocket server wsdata', wsdata) //wsdata {"id":"69a33d3c-ad3a-448f-8623-fe12c65effa2","code":"24060000313","kitchenstatus":"Not Sent","transactionstatus":"Open"}

          let status = watch('tab')
          refetch()
          if(status == wsdata.transactionstatus || status == wsdata.prevTransactionstatus) {
            dataForTab(wsdata.prevTransactionstatus||'Open')
          }
          
        }
      };

      ws.current.onclose = () => {
          console.log('WebSocket closed.');
          clearInterval(intervalId.current);
          if(window.location.pathname == '/mode/payment-terminal') {
            console.log('WebSocket Reconnecting...')
            setTimeout(()=>{
              connectWebSocket()
            }, 1000); // Reconnect after 1 second
          }
      };

      ws.current.onerror = (error: any) => {
          console.log('WebSocket error:', error);
          ws.current.close();
      };
    };
    useEffect(() => {
      connectWebSocket();
      return () => {
        ws.current.close();
        clearInterval(intervalId.current);
      };
    }, []);
    // const { data, isFetching, isSuccess } = apiPrivate.useCustomerId<ICustomer>(valueid)
    const { register, control, reset, setValue, watch, handleSubmit, formState: { errors, isSubmitting } } = useForm<any>({
        resolver: yupResolver(schema),
        defaultValues: {
          tab: "Open"
        }
    })

    const createHospTrans = async(tableInfo: any, item?: any) => {
      let tempData = {
          "saletype": tableInfo?.qrcodeordertype,
          "merchantkey": staffInfo.Merchant?.merchantkey,
          "merchantcode": staffInfo.Merchant?.merchantcode,
          "merchantname": staffInfo.Merchant?.merchantname,
          "merchantid": staffInfo.merchantid,
          "storeno": staffInfo.Merchant?.storecode,
          "tableid": item?.tableid?item.tableid:tableInfo?.id,
          "tableno": item?.tableno?item.tableno:tableInfo?.tableno,
          "tablename": item?.tablename?item.tablename:tableInfo?.tablename,
          "sectionid": tableInfo?.sectionid,
          "sectioncode": tableInfo?.sectioncode,
          "sectionname": tableInfo?.sectionname,
          "transactiontype": "Sales",
          "transactionstatus": "Open",
          "kitchenstatus": "Not Sent",
          
          "staffcode": staffInfo.code,
          "staffreceiptname": staffInfo.nameonreceipt,
          "noofitems": 1,
          "noofitemlines": 1,
          "linediscount": 0,
          "totaldiscount": 0,
          "customerinfo": {
              "name": "Guest"
          },
          "HospTransactionLines": []
      }
      let urlApi = `smarthub/hosptransactions/upsertwithdetails/`
      let {data} = await ApiService.post(urlApi, tempData)
      setDataModal(data)
    }
    
    const { t } = useTranslation()
    const toolbar = <div className="d-flex flex-center gap-5">
      {/* <span className="btn btn-light-primary btn-active-primary btn-sm cursor-pointer"><i className="bi bi-building-check me-2"></i> All checks</span> */}
      <span className="btn btn-light-primary btn-active-primary btn-sm cursor-pointer" onClick={e=>setEdit(true)}><i className="bi bi-plus-lg me-2"></i>{t('payment-terminal-deep.new-orders')}</span>
      {/* <span className="btn btn-light-primary btn-active-primary btn-sm cursor-pointer" onClick={e=>setEdit(!edit)}><i className="bi bi-search me-2"></i> Lookups</span> */}
      <span className="btn btn-light-primary btn-active-primary btn-sm cursor-pointer"><i className="bi bi-arrow-left-right me-2"></i>{t('payment-terminal-deep.switch-user')}</span>
      <Dropdown>
        <Dropdown.Toggle as={'div'} className='btn btn-light-primary btn-active-primary btn-sm btn-icon h-el-after'>
          <i className="bi bi-three-dots-vertical" />
        </Dropdown.Toggle>
        <Dropdown.Menu className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-200px p-2' >
            
            <Dropdown.Item as='div' className={clsx('menu-item')} >
                <a href='#' className=''>
                  <span className="menu-link">{t('payment-terminal-deep.manager-online-orders')}</span>
                </a>
            </Dropdown.Item>
            <Dropdown.Item as='div' className={clsx('menu-item')} >
                <a href='#' className=''> 
                  <span className="menu-link">{t('payment-terminal-deep.find-checks-and-issue-result')}</span>
                </a>
            </Dropdown.Item>
            <Dropdown.Item as='div' className={clsx('menu-item')} >
                <a href='#' className=''> 
                  <span className="menu-link">{t('payment-terminal-deep.switch-users')}</span>
                </a>
            </Dropdown.Item>
            <Dropdown.Item as='div' className={clsx('menu-item')} >
                <a href='#' className=''> 
                  <span className="menu-link">{t('payment-terminal-deep.device-status')}</span>
                </a>
            </Dropdown.Item>
            
        </Dropdown.Menu>
    </Dropdown>
    </div>
    

    const onSubmitFilter:any = (data: any) => {
        if(!filter) return data
        let tempData = data.map((item:any)=> item)
        if(filter.statusLocal ) {
            tempData = tempData.filter((i:any)=> i.statusLocal == filter.statusLocal)
        }
        if(filter.departmentcode) {
            tempData = tempData.filter((i:any)=> i.departmentid == filter.departmentid)
        }
        if(filter.createdby) {
            tempData = tempData.filter((i:any)=> i.createdby == filter.createdby)
        }
        let fromdate = filter.fromdate ? filter.fromdate: new Date()
        if(fromdate) {
            fromdate = new Date(fromdate).setHours(0,0,0)
        }
        let todate = filter.todate? filter.todate[0]: null 
        if(todate) {
            todate = new Date(todate).setHours(23,59,59)
        }
        if(todate) {
            tempData = tempData.filter((i:any)=> new Date(i.createdat).getTime() <= todate && new Date(i.createdat).getTime() >= fromdate)
        }
        // console.log('tempData',tempData)
        return tempData
    }

    
    
    return <>
        <ContentHeader title={t('payment-terminal')} linkBack={'/mode'} items={[{ title: t('mode'), path: parentLink(pathname) }]} elements={toolbar} />
        {/* <div className=' border-gray-300 mh-auto pb-0'>
              <div className=' mb-0 row'>
                <div className="col-7">
                  <div
                    className='card flex-fill d-flex flex-row text-nowrap scrollx-auto tab-custom-hub'
                    data-kt-scroll='true'
                    data-kt-scroll-activate='{default: false, lg: true}'
                    data-kt-scroll-width='auto'
                    data-kt-scroll-offset='0'
                  >
                    {tabs.map((item, index) => {
                      return (<span
                        key={index}
                        id={item.id}
                        onClick={() => {
                          setTab(item.id)
                          refetch()
                        }}
                        className={clsx(
                          ' cursor-pointer item-tab',
                          tab != item.id && 'text-gray-600 ',
                          tab == item.id && 'active',
                          index == 0 && ''
                        )}>
                        {item.name} <span className="number-circle bg-gray">{item.lengData}</span>
                      </span>)
                    })}
                  </div>
                </div>
                
              </div>
              
              {tabs.map((item, index) => {
                  return <DivRoot key={index}>
                      {item.id == tab && item.component}
                  </DivRoot>
              })}
            </div> */}
        <MBar dataApi={dataTab} dataCount={countDataTabs} callBackFunc={callBackFunc} refetchData={refetch}/>
        <InputDate
          className={clsx('form-control form-control-sm w-100 d-none',)}
          value={''}
          
          onChange={e=>{}}
          />
    <ModalAddEditOrder createHospTrans={createHospTrans} refetchCurrent={refetch} show={edit} dataModal={dataModal} setDataModal={setDataModal} setShow={setEdit}/>
        
        
    </>
}

export { PaymentTerminal };

